body {
  font-family: monospace;
}

h1 {
  font-size: 1em;
  margin: 0;
}

p {
  margin: 0;
}

button {
  font-family: monospace;
}

.box {
  margin: 4pt;
  padding: 4pt;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
}
.box-gray {
  background-color: #ddd;
}

.airvis {
  width: 400px;
  height: 400px;
}

.ty-cargo {
  display: inline-block;
  color: blue;
  font-weight: bold;
}

.ty-passanger {
  display: inline-block;
  color: green;
  font-weight: bold;
}

.resource {
  color: red;
  font-weight: bold;
}

.split {
  display: flex;
}

.journal-item-info {
  color: gray;
}

@keyframes blink {
  0% {
    color: rgba(0, 0, 0, 255);
  }
  100% {
    color: rgba(255, 0, 0, 255);
  }
}

.capacity-full {
  font-weight: bold;

  animation: blink 1s ease-out 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.capacity-full:after {
  content: " 수용 용량이 가득 찼습니다.";
}

.flight-name {
  margin: 0;
  margin-right: 0.2em;
  font-weight: bold;
}

.flight-name-highlight {
  color: purple;
}
